<template>
  <!-- width 16, height 14-->
  <!-- 0 0 16 14 -->
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M0 2.6001C0 1.49553 0.895431 0.600098 2 0.600098H14C15.1046 0.600098 16 1.49553 16 2.6001V11.4001C16 12.5047 15.1046 13.4001 14 13.4001H2C0.895431 13.4001 0 12.5047 0 11.4001V2.6001ZM1.59961 5.3999H14.3996V10.7999C14.3996 11.3522 13.9519 11.7999 13.3996 11.7999H2.59961C2.04733 11.7999 1.59961 11.3522 1.59961 10.7999V5.3999ZM2.39961 3.8002C2.84144 3.8002 3.19961 3.44202 3.19961 3.0002C3.19961 2.55837 2.84144 2.2002 2.39961 2.2002C1.95778 2.2002 1.59961 2.55837 1.59961 3.0002C1.59961 3.44202 1.95778 3.8002 2.39961 3.8002Z"
    fill="currentColor"
  />
</template>
